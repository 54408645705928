// Here you can add other styles
.btn-xs, .btn-group-sm > .btn {
    padding: 0rem 1rem;
    font-size: 0.76563rem;
    line-height: 1.5;
    border-radius: 0.2rem;
    margin-right: 5px;
    margin-left: 5px;
}
.btn a{
    color: #fff;
}

.main .container-fluid {
    padding: 0 10px;
}

ol.breadcrumb {
    margin-bottom: 0.5em;
}

.btn-c-orange {
    background-color: #f19402;
    border: #f19402 1px solid;
    color: #ffffff;
    font-weight: 500;
}

.btn-c-orange:hover {
    color: #ffffff;
    background-color: #c07805;
}

.upload-btn-wrapper {
    position: relative;
    overflow: hidden;
    display: inline-block;
    margin-bottom: 10px;
}
  
.upload-btn-wrapper input[type=file] {
    font-size: 100px;
    position: absolute;
    left: 0;
    top: 0;
    opacity: 0;
}

.table-upload-xlsx thead {
    line-height: 0em;
    background-color: #f0f0f0;
}

.table-upload-xlsx tbody {
    line-height: 0.5em;
}

.react-datepicker-wrapper {
    width: 100%;
}

.col-filter {
    margin: auto;
}